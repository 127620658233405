import React from 'react';

const AgouraFooter = () => {
    return (
        <div className={'AgouraFooter'}>
            <div>Agoura Animal Care Center</div>
            <div>29525 Agoura Rd</div>
            <div>Agoura Hills, CA 91301</div>
            <div>(818) 991-0071</div>
        </div>
    );
};

export default AgouraFooter;
